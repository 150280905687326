<template>
  <v-container class="fill-height">
    <v-row class="justify-center align-center">
      <v-col cols="12" sm="8" md="5" xl="3">
        <v-card
          class="py-10 rounded-xl"
          style="
            background-color: #fff;
            box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
          "
        >
          <v-card-title class="justify-center text-h4 font-weight-bold py-4"
            >Lupa Password</v-card-title
          >
          <v-card-subtitle
            class="text-center grey--text text--darken-1 subtitle-1"
            >Permintaan Ganti Password Administrator</v-card-subtitle
          >
          <v-card-text
            class="d-flex flex-column align-start mt-6"
            :class="$vuetify.breakpoint.smAndDown ? 'px-4' : 'px-10'"
          >
            <v-form
              style="width: 100%"
              ref="form-forgot-password-email"
              v-model="validEmail"
              lazy-validation
              @keyup.native.enter="requestOTP"
            >
              <div class="subtitle-2">
                Alamat Email <i>(gmail)</i>
                <small style="color: #e74c3c"><i> *wajib diisi</i></small>
              </div>
              <div class="d-flex" style="width: 100%">
                <v-text-field
                  dense
                  solo
                  autocomplete="off"
                  color="#4ab762"
                  type="text"
                  placeholder="ex. example@gmail.com"
                  class="rounded-l-lg rounded-r-0 mb-3"
                  :disabled="loading"
                  v-model="email"
                  :error-messages="validationEmail.email"
                  @keyup="validationEmail.email = ''"
                  :rules="[
                    (v) => !!v || `Alamat email tidak boleh kosong`,
                    (v) =>
                      (v && v.length <= 100) ||
                      'Alamat email maksimal 100 karakter',
                    (v) =>
                      /([a-zA-Z0-9]+)([\.{1}])?([a-zA-Z0-9]+)\@gmail([\.])com/g.test(
                        v
                      ) || 'Alamat email harus gmail',
                  ]"
                >
                  <template v-slot:message="{ message }">
                    <v-tooltip top max-width="250" color="#e74c3c">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          size="22"
                          class="mr-2 ml-n2"
                          color="#e74c3c"
                          v-bind="attrs"
                          v-on="on"
                          >mdi-alert-circle-outline</v-icon
                        >
                      </template>
                      <span>{{ message }}</span>
                    </v-tooltip>
                  </template>
                </v-text-field>
                <v-btn
                  depressed
                  class="text-none white--text rounded-r-lg rounded-l-0"
                  color="#3498db"
                  height="38"
                  :disabled="loading"
                  @click="requestOTP"
                  >Kirim Kode</v-btn
                >
              </div>
            </v-form>

            <v-form
              style="width: 100%"
              ref="form-forgot-password"
              v-model="valid"
              lazy-validation
              @keyup.native.enter="doReset"
            >
              <div class="subtitle-2">
                Kode OTP
                <small style="color: #e74c3c"><i>*wajib diisi</i></small>
              </div>
              <v-text-field
                dense
                solo
                autocomplete="off"
                color="#4ab762"
                type="text"
                placeholder="Tulis disini"
                class="rounded-lg mb-3"
                :disabled="loading"
                v-model="form.code"
                :error-messages="validation.code"
                @keyup="validation.code = ''"
                :rules="[
                  (v) => !!v || `Kode OTP tidak boleh kosong`,
                  (v) => (v && v.length == 6) || 'Kode OTP harus 6 karakter',
                ]"
              >
                <template v-slot:message="{ message }">
                  <v-tooltip top max-width="250" color="#e74c3c">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        size="22"
                        class="mr-2 ml-n2"
                        color="#e74c3c"
                        v-bind="attrs"
                        v-on="on"
                        >mdi-alert-circle-outline</v-icon
                      >
                    </template>
                    <span>{{ message }}</span>
                  </v-tooltip>
                </template>
              </v-text-field>
              <div class="subtitle-2">
                Password Baru
                <small style="color: #e74c3c"><i>*wajib diisi</i></small>
              </div>
              <v-text-field
                dense
                solo
                autocomplete="new-password"
                color="#4ab762"
                :type="showPassword ? 'text' : 'password'"
                placeholder="Tulis disini"
                class="rounded-lg mb-3"
                :disabled="loading"
                v-model="form.password"
                :error-messages="validation.password"
                @keyup="validation.password = ''"
                :rules="[
                  (v) => !!v || `Password baru tidak boleh kosong`,
                  (v) =>
                    (v && v.length >= 6) || `Password baru minimal 6 karakter`,
                ]"
              >
                <template v-slot:message="{ message }">
                  <v-tooltip top max-width="250" color="#e74c3c">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        size="22"
                        class="mr-2 ml-n2"
                        color="#e74c3c"
                        v-bind="attrs"
                        v-on="on"
                        >mdi-alert-circle-outline</v-icon
                      >
                    </template>
                    <span>{{ message }}</span>
                  </v-tooltip>
                </template>
              </v-text-field>

              <div class="subtitle-2">
                Ulangi Password Baru
                <small style="color: #e74c3c"><i>*wajib diisi</i></small>
              </div>
              <v-text-field
                dense
                solo
                autocomplete="new-password"
                color="#4ab762"
                :type="showPassword ? 'text' : 'password'"
                placeholder="Tulis disini"
                class="rounded-lg mb-3"
                :disabled="loading"
                v-model="form.password_confirm"
                :error-messages="validation.password_confirm"
                @keyup="validation.password_confirm = ''"
                :rules="[
                  (v) => !!v || `Ulangi password baru tidak boleh kosong`,
                  (v) =>
                    (v && v.length >= 6) ||
                    `Ulangi password baru minimal 6 karakter`,
                ]"
              >
                <template v-slot:message="{ message }">
                  <v-tooltip top max-width="250" color="#e74c3c">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        size="22"
                        class="mr-2 ml-n2"
                        color="#e74c3c"
                        v-bind="attrs"
                        v-on="on"
                        >mdi-alert-circle-outline</v-icon
                      >
                    </template>
                    <span>{{ message }}</span>
                  </v-tooltip>
                </template>
              </v-text-field>
            </v-form>
            <div
              class="d-flex flex-row justify-space-between"
              style="width: 100%"
            >
              <v-checkbox
                dense
                class="mt-0 pt-0 checkbox-pwd"
                v-model="showPassword"
                color="#4ab762"
                :label="!showPassword ? 'Show password' : 'Hide password'"
                hide-details
              ></v-checkbox>
              <v-btn
                color="#4ab762"
                text
                class="text-none px-0"
                depressed
                small
                @click="$router.push({ name: 'login' })"
                >Login disini</v-btn
              >
            </div>
          </v-card-text>
          <v-card-actions
            class="justify-center"
            :class="$vuetify.breakpoint.smAndDown ? 'px-4' : 'px-10'"
          >
            <v-btn
              large
              block
              depressed
              color="#4ab762"
              class="font-weight-bold white--text text-none rounded-lg"
              @click="doReset"
              :disabled="loading"
              :loading="loading"
            >
              <div class="flex-grow-1 ml-6 title">Reset Password</div>
              <v-icon>mdi-lock-reset</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "ForgotPassword",
  data: () => ({
    showPassword: false,

    loading: false,

    valid: true,
    validEmail: true,

    email: "",
    validationEmail: {
      email: "",
    },

    form: {
      code: "",
      password: "",
      password_confirm: "",
      device: "web",
    },
    validation: {
      code: "",
      password: "",
      password_confirm: "",
      device: "",
    },
  }),

  methods: {
    async doReset() {
      let isValid = this.$refs["form-forgot-password"].validate();

      if (isValid) {
        this.$store.state.overlay.show = true;
        this.$store.state.overlay.text = "Menyimpan perubahan password...";

        let response = await this.$post(
          "admin/forget_password/reset",
          this.form
        );

        this.$store.state.overlay.show = false;

        if (response.status == 200) {
          this.$store.state.snackbar = {
            show: true,
            text: "Berhasil merubah password, Anda akan diarahkan ke halaman Login",
            color: "#2ecc71",
          };

          this.$refs["form-forgot-password"].resetValidation();

          setTimeout(() => {
            this.$router.push({ name: "login" });
          }, 2000);
        } else if (response.status == 412) {
          this.$store.state.snackbar = {
            show: true,
            text: "Cek kembali form Anda",
            color: "#e74c3c",
          };

          Object.assign(this.validation, response.results.data);
        } else if (response.status == 400) {
          this.$store.state.snackbar = {
            show: true,
            text: response.message,
            color: "#e74c3c",
          };
        }
      }
    },

    async requestOTP() {
      let isValid = this.$refs["form-forgot-password-email"].validate();

      if (isValid) {
        this.$store.state.overlay.show = true;
        this.$store.state.overlay.text = "Mengirimkan kode OTP...";

        const response = await this.$post(`admin/forget_password`, {
          email: this.email,
        });

        this.$store.state.overlay.show = false;

        if (response.status === 200) {
          this.$store.state.snackbar = {
            show: true,
            text: "Berhasil mengirim kode OTP, silahkan cek email Anda",
            color: "#2ecc71",
          };

          this.$refs["form-forgot-password-email"].resetValidation();

          Object.assign(this.validationEmail, { email: "" });
        } else if (response.status == 412) {
          this.$store.state.snackbar = {
            show: true,
            text: "Cek kembali form Anda",
            color: "#e74c3c",
          };

          Object.assign(this.validationEmail, response.results.data);
        } else if (response.status == 400) {
          this.$store.state.snackbar = {
            show: true,
            text: response.message,
            color: "#e74c3c",
          };
        }
      }
    },
  },
};
</script>

<style>
</style>