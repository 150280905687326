var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"fill-height"},[_c('v-row',{staticClass:"justify-center align-center"},[_c('v-col',{attrs:{"cols":"12","sm":"8","md":"5","xl":"3"}},[_c('v-card',{staticClass:"py-10 rounded-xl",staticStyle:{"background-color":"#fff","box-shadow":"rgba(0, 0, 0, 0.15) 0px 2px 8px"}},[_c('v-card-title',{staticClass:"justify-center text-h4 font-weight-bold py-4"},[_vm._v("Lupa Password")]),_c('v-card-subtitle',{staticClass:"text-center grey--text text--darken-1 subtitle-1"},[_vm._v("Permintaan Ganti Password Administrator")]),_c('v-card-text',{staticClass:"d-flex flex-column align-start mt-6",class:_vm.$vuetify.breakpoint.smAndDown ? 'px-4' : 'px-10'},[_c('v-form',{ref:"form-forgot-password-email",staticStyle:{"width":"100%"},attrs:{"lazy-validation":""},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.requestOTP.apply(null, arguments)}},model:{value:(_vm.validEmail),callback:function ($$v) {_vm.validEmail=$$v},expression:"validEmail"}},[_c('div',{staticClass:"subtitle-2"},[_vm._v(" Alamat Email "),_c('i',[_vm._v("(gmail)")]),_c('small',{staticStyle:{"color":"#e74c3c"}},[_c('i',[_vm._v(" *wajib diisi")])])]),_c('div',{staticClass:"d-flex",staticStyle:{"width":"100%"}},[_c('v-text-field',{staticClass:"rounded-l-lg rounded-r-0 mb-3",attrs:{"dense":"","solo":"","autocomplete":"off","color":"#4ab762","type":"text","placeholder":"ex. example@gmail.com","disabled":_vm.loading,"error-messages":_vm.validationEmail.email,"rules":[
                  function (v) { return !!v || "Alamat email tidak boleh kosong"; },
                  function (v) { return (v && v.length <= 100) ||
                    'Alamat email maksimal 100 karakter'; },
                  function (v) { return /([a-zA-Z0-9]+)([\.{1}])?([a-zA-Z0-9]+)\@gmail([\.])com/g.test(
                      v
                    ) || 'Alamat email harus gmail'; } ]},on:{"keyup":function($event){_vm.validationEmail.email = ''}},scopedSlots:_vm._u([{key:"message",fn:function(ref){
                var message = ref.message;
return [_c('v-tooltip',{attrs:{"top":"","max-width":"250","color":"#e74c3c"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2 ml-n2",attrs:{"size":"22","color":"#e74c3c"}},'v-icon',attrs,false),on),[_vm._v("mdi-alert-circle-outline")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(message))])])]}}]),model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('v-btn',{staticClass:"text-none white--text rounded-r-lg rounded-l-0",attrs:{"depressed":"","color":"#3498db","height":"38","disabled":_vm.loading},on:{"click":_vm.requestOTP}},[_vm._v("Kirim Kode")])],1)]),_c('v-form',{ref:"form-forgot-password",staticStyle:{"width":"100%"},attrs:{"lazy-validation":""},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.doReset.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('div',{staticClass:"subtitle-2"},[_vm._v(" Kode OTP "),_c('small',{staticStyle:{"color":"#e74c3c"}},[_c('i',[_vm._v("*wajib diisi")])])]),_c('v-text-field',{staticClass:"rounded-lg mb-3",attrs:{"dense":"","solo":"","autocomplete":"off","color":"#4ab762","type":"text","placeholder":"Tulis disini","disabled":_vm.loading,"error-messages":_vm.validation.code,"rules":[
                function (v) { return !!v || "Kode OTP tidak boleh kosong"; },
                function (v) { return (v && v.length == 6) || 'Kode OTP harus 6 karakter'; } ]},on:{"keyup":function($event){_vm.validation.code = ''}},scopedSlots:_vm._u([{key:"message",fn:function(ref){
              var message = ref.message;
return [_c('v-tooltip',{attrs:{"top":"","max-width":"250","color":"#e74c3c"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2 ml-n2",attrs:{"size":"22","color":"#e74c3c"}},'v-icon',attrs,false),on),[_vm._v("mdi-alert-circle-outline")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(message))])])]}}]),model:{value:(_vm.form.code),callback:function ($$v) {_vm.$set(_vm.form, "code", $$v)},expression:"form.code"}}),_c('div',{staticClass:"subtitle-2"},[_vm._v(" Password Baru "),_c('small',{staticStyle:{"color":"#e74c3c"}},[_c('i',[_vm._v("*wajib diisi")])])]),_c('v-text-field',{staticClass:"rounded-lg mb-3",attrs:{"dense":"","solo":"","autocomplete":"new-password","color":"#4ab762","type":_vm.showPassword ? 'text' : 'password',"placeholder":"Tulis disini","disabled":_vm.loading,"error-messages":_vm.validation.password,"rules":[
                function (v) { return !!v || "Password baru tidak boleh kosong"; },
                function (v) { return (v && v.length >= 6) || "Password baru minimal 6 karakter"; } ]},on:{"keyup":function($event){_vm.validation.password = ''}},scopedSlots:_vm._u([{key:"message",fn:function(ref){
              var message = ref.message;
return [_c('v-tooltip',{attrs:{"top":"","max-width":"250","color":"#e74c3c"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2 ml-n2",attrs:{"size":"22","color":"#e74c3c"}},'v-icon',attrs,false),on),[_vm._v("mdi-alert-circle-outline")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(message))])])]}}]),model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}}),_c('div',{staticClass:"subtitle-2"},[_vm._v(" Ulangi Password Baru "),_c('small',{staticStyle:{"color":"#e74c3c"}},[_c('i',[_vm._v("*wajib diisi")])])]),_c('v-text-field',{staticClass:"rounded-lg mb-3",attrs:{"dense":"","solo":"","autocomplete":"new-password","color":"#4ab762","type":_vm.showPassword ? 'text' : 'password',"placeholder":"Tulis disini","disabled":_vm.loading,"error-messages":_vm.validation.password_confirm,"rules":[
                function (v) { return !!v || "Ulangi password baru tidak boleh kosong"; },
                function (v) { return (v && v.length >= 6) ||
                  "Ulangi password baru minimal 6 karakter"; } ]},on:{"keyup":function($event){_vm.validation.password_confirm = ''}},scopedSlots:_vm._u([{key:"message",fn:function(ref){
              var message = ref.message;
return [_c('v-tooltip',{attrs:{"top":"","max-width":"250","color":"#e74c3c"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2 ml-n2",attrs:{"size":"22","color":"#e74c3c"}},'v-icon',attrs,false),on),[_vm._v("mdi-alert-circle-outline")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(message))])])]}}]),model:{value:(_vm.form.password_confirm),callback:function ($$v) {_vm.$set(_vm.form, "password_confirm", $$v)},expression:"form.password_confirm"}})],1),_c('div',{staticClass:"d-flex flex-row justify-space-between",staticStyle:{"width":"100%"}},[_c('v-checkbox',{staticClass:"mt-0 pt-0 checkbox-pwd",attrs:{"dense":"","color":"#4ab762","label":!_vm.showPassword ? 'Show password' : 'Hide password',"hide-details":""},model:{value:(_vm.showPassword),callback:function ($$v) {_vm.showPassword=$$v},expression:"showPassword"}}),_c('v-btn',{staticClass:"text-none px-0",attrs:{"color":"#4ab762","text":"","depressed":"","small":""},on:{"click":function($event){return _vm.$router.push({ name: 'login' })}}},[_vm._v("Login disini")])],1)],1),_c('v-card-actions',{staticClass:"justify-center",class:_vm.$vuetify.breakpoint.smAndDown ? 'px-4' : 'px-10'},[_c('v-btn',{staticClass:"font-weight-bold white--text text-none rounded-lg",attrs:{"large":"","block":"","depressed":"","color":"#4ab762","disabled":_vm.loading,"loading":_vm.loading},on:{"click":_vm.doReset}},[_c('div',{staticClass:"flex-grow-1 ml-6 title"},[_vm._v("Reset Password")]),_c('v-icon',[_vm._v("mdi-lock-reset")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }